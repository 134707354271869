import * as React from 'react';
import PropTypes from 'prop-types';

import cl from 'classnames';
import { Link } from 'gatsby';
import styled from 'styled-components';

const steps = [
  'Set up',
  'BẮT ĐẦU HỌC',
  'GIẢI ĐÁP',
  'SỬA BÀI',
  'LUYỆN TẬP',
  'NHẬN HỌC BỔNG'
];

export const OnlineHeaderStep = React.memo(props => {
  const { activeStep } = props;

  return (
    <HeaderStep>
      <ul>
        {steps.map((step, idx) => {
          const isLastStep = idx === steps.length - 1;
          return (
            <li key={step} className={cl({ active: idx <= activeStep })}>
              {isLastStep ? (
                <a
                  href={`/online/step${idx + 1}`}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <span>{idx + 1}</span>
                  <h6>{step}</h6>
                </a>
              ) : (
                <Link to={`/online/step${idx + 1}`}>
                  <span>{idx + 1}</span>
                  <h6>{step}</h6>
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </HeaderStep>
  );
});

const HeaderStep = styled.div`
  position: fixed;
  top: 0;
  height: 72px;
  max-width: 1140px;
  width: calc(100% - 40px);
  padding-left: 190px;
  z-index: 99;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;

  @media screen and (max-width: 1024px) {
    display: none;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      a {
        display: inline-flex;
        align-items: center;

        h6 {
          margin: 0;
          margin-left: 10px;
          color: #a5adba;
          font-weight: bold;
          font-size: 16px;
          text-transform: uppercase;
        }

        span {
          font-weight: 500;
          width: 16px;
          height: 16px;
          font-size: 12px;
          color: #fff;
          background: #a5adba;
          border-radius: 50px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }
      }

      &.active {
        a {
          h6 {
            color: #d14242;
          }
          span {
            background: #d14242;
          }
        }

        &::before {
          background: #d14242;
        }
      }

      &:not(:first-child)::before {
        content: '';
        width: 30px;
        height: 2px;
        background: #b3bac5;
        border-radius: 2.5px;
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px;

        @media screen and (max-width: 1200px) {
          width: 30px;
        }
      }
    }
  }
`;

OnlineHeaderStep.propTypes = {
  activeStep: PropTypes.number.isRequired
};
